import NextLink from 'next/link';

import routes from '@/core/routes';
import LogoAnimation from '@/snippets/LogoAnimation';
import { Box, Button, Typography } from '@mui/material';

export default function Page() {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 10,
      minHeight: '100vh',
    }}>

      <Box component={NextLink} href={routes.home}>
        <LogoAnimation size={140} followCursor />
      </Box>

      <Box>
        <Typography variant="h1" align="center">
          404
        </Typography>
        <Typography variant="body1" align="center">
          Page not found
        </Typography>
      </Box>

      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: 5,
      }}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          component={NextLink}
          href={routes.home}
          data-button-gradient
          data-button-rounded
        >
          Go to home
        </Button>
      </Box>
    </Box>
  )
}
